import React from 'react';

import { Dialog } from './Dialog';

export const UpdateShortcutsDialog = ({
  isOpen,
  onConfirm,
  onCancel,
}: { isOpen: boolean; onConfirm: () => void; onCancel: () => void }) => {
  if (isOpen) {
    return (
      <div>
        <Dialog
          id="update-shortcuts"
          title="Update shortcuts?"
          subtitle="Are you sure you want to update all of your current shortcuts?"
          actionTitle="Update shortcuts"
          cancelTitle="Cancel"
          action={onConfirm}
          cancelAction={onCancel}
        />
      </div>
    );
  }

  return null;
};
