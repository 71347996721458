import React from 'react';

import Icon from './Icon';

export default function ResetIcon({ className = '' }: { className?: string }): JSX.Element {
  return (
    <Icon text="Reset">
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4658 2.56465C8.49046 1.86164 5.44809 3.02935 3.68587 5.40078L3.68587 4.22501C3.68587 3.81079 3.35008 3.47501 2.93587 3.47501C2.52165 3.47501 2.18587 3.81079 2.18587 4.22501L2.18587 7.1355C2.18587 7.54972 2.52166 7.8855 2.93587 7.8855L2.94795 7.88541H3.52146C3.53064 7.88558 3.53981 7.88558 3.54897 7.88541L5.84651 7.88541C6.26073 7.88541 6.59651 7.54962 6.59651 7.13541C6.59651 6.72119 6.26073 6.38541 5.84651 6.38541L4.82448 6.38541C6.22519 4.42613 8.70072 3.45262 11.1208 4.02446C13.9429 4.69127 15.8919 7.27089 15.7623 10.1678C15.6326 13.0647 13.4611 15.4599 10.5907 15.872C10.1807 15.9309 9.89602 16.311 9.95488 16.721C10.0137 17.131 10.3938 17.4157 10.8039 17.3568C14.3876 16.8423 17.0989 13.8518 17.2608 10.2349C17.4226 6.61795 14.9893 3.3972 11.4658 2.56465Z"
          fill="black"
        />
        <path
          d="M2.35706 10.2924C2.67625 9.97298 3.1939 9.97254 3.51362 10.2915C3.6675 10.4449 3.75398 10.6534 3.75398 10.8707C3.75398 11.0881 3.6675 11.2965 3.51362 11.45C3.1939 11.7689 2.67625 11.7684 2.35706 11.449C2.03788 11.1295 2.03788 10.6119 2.35706 10.2924Z"
          fill="black"
        />
        <path
          d="M3.56996 13.4454C3.88914 13.126 4.40679 13.1256 4.72651 13.4445C4.88039 13.598 4.96688 13.8064 4.96688 14.0237C4.96688 14.2411 4.88039 14.4495 4.72651 14.603C4.40679 14.9219 3.88914 14.9215 3.56996 14.602C3.25077 14.2825 3.25077 13.7649 3.56996 13.4454Z"
          fill="black"
        />
        <path
          d="M6.24013 15.6256L6.23841 15.6273C6.08453 15.7808 5.99805 15.9892 5.99805 16.2065C5.99805 16.4239 6.08453 16.6323 6.23841 16.7858C6.55813 17.1047 7.07578 17.1043 7.39497 16.7848C7.71415 16.4653 7.71415 15.9477 7.39497 15.6282C7.07636 15.3094 6.55999 15.3084 6.24013 15.6256Z"
          fill="black"
        />
      </svg>
    </Icon>
  );
}
